import Axios from "axios";

let http = "https://fisico.gobiernocontigo.com/api/v1/";

export default {
  index() {
    return Axios.get(`${http}banner/`);
  },
  publicIndex() {
    return Axios.get(`${http}banner/?active=true`);
  },

  // show(id) {},

  update(id, data) {
    return Axios.put(`${http}banner/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  updateState(data) {
    delete data.attached;

    return Axios.put(`${http}banner/${data.id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
};
