<template>
  <div id="a-graphics-details">
    <div id="a-general-img-card">
      <img
        :class="item.ruta_imagen != null ? 'a-card-img' : 'a-default-card-img'"
        :src="
          item.ruta_imagen != null
            ? item.ruta_imagen
            : require('@/assets/images/public/image.svg')
        "
      />
      <div
        id="a-line-over-img"
        :style="
          item.projectType != null
            ? `background: #${item.projectType.color}`
            : 'background: #00b7ff'
        "
      />
    </div>
    <div
      v-if="item.projectType != null"
      id="a-mobile-project-type-card"
      :style="`background: #${item.projectType.color}`"
    >
      <img
        id="a-card-type"
        :src="
          require(`@/assets/icons/builders/builder-${projectTypeImage}.svg`)
        "
      />
      <div>
        <strong>Urbanización {{ item.name }} </strong>
        <span class="a-photoshoot">es un proyecto</span>
        <br />
        <span id="a-type-text">{{ item.projectType.name }}</span>
      </div>
    </div>

    <slot></slot>
  </div>

  <div id="a-detail-information">
    <div id="a-detail-information-text">
      <div
        v-for="(display, index) in displayInfo"
        :key="index"
        class="a-project-detail-info"
      >
        <div v-if="item[display.detail] != null">
          <strong>{{ display.name }}</strong>
          <br />
          <span v-if="display.detail != 'builderProject'">{{
            item[display.detail]
          }}</span>
          <div v-if="item[display.detail].length > 0">
            <div v-for="(builder, index) in item[display.detail]" :key="index">
              <span v-if="builder.idBuilder">
                {{ builder.idBuilder.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="item.projectType != null"
      id="a-desktop-project-type-card"
      :style="`background: #${item.projectType.color}`"
    >
      <img
        id="a-card-type"
        :src="
          require(`@/assets/icons/builders/builder-${projectTypeImage}.svg`)
        "
      />
      <br />
      <strong>Urbanización {{ item.name }} </strong>
      <span class="a-photoshoot">es un proyecto</span>
      <br />
      <span id="a-type-text">{{ item.projectType.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicDetailProject",
  props: {
    item: Object,
  },
  data() {
    return {
      displayInfo: [
        { name: "Descripción", detail: "description" },
        { name: "Cod. Agencia Nacional de tierras", detail: "codeAnt" },
        { name: "Dirección", detail: "address" },
        { name: "Unidades de vivienda", detail: "housingUnits" },
        { name: "Constructoras:", detail: "builderProject" },
      ],
      mapKey: 0,
    };
  },
  computed: {
    projectTypeImage() {
      let icon = "undefined";
      if (this.item.projectType != null) {
        if (this.item.projectType.color == "00d986") {
          icon = "green";
        }
        if (this.item.projectType.color == "d90024") {
          icon = "red";
        }
        if (this.item.projectType.color == "fdc30f") {
          icon = "yellow";
        }
        if (this.item.projectType.color == "2b5ac4") {
          icon = "blue";
        }
        if (this.item.projectType.color == "ca00d9") {
          icon = "pink";
        }
      }
      return icon;
    },
  },
};
</script>

<style>
@media (max-width: 480px) {
  #a-desktop-project-type-card {
    display: none !important;
  }

  #a-graphics-details {
    display: flex;
    flex-flow: column nowrap;
  }

  #a-mobile-project-type-card {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    font-size: 13px;
  }
  #a-type-text {
    font-size: 15px !important;
  }
  #a-card-type {
    height: 80px;
  }

  .p-dialog .p-dialog-content,
  .p-dialog .p-dialog-header {
    padding: 15px !important;
  }

  .p-dialog .p-dialog-header .p-dialog-title {
    text-align: left;
    font-size: 14px !important;
  }

  .a-card-img,
  .a-default-card-img {
    height: 150px !important;
  }
  #a-general-img-card {
    width: 100% !important;
    margin-right: 0 !important;
  }
  #a-line-over-img {
    width: 70% !important;
  }

  #a-detail-information-text,
  #a-graphics-details .vue-map-container {
    width: 100% !important;
    margin: 0 0 12px 0 !important;
  }
}
</style>

<style>
#a-detail-information {
  background: white;
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 20px 30px;

  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);

  position: relative;
}

#a-detail-information-text {
  width: calc(100% - 250px);
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  text-align: start;
}
.a-project-detail-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Montserrat;
  font-size: 12px;
}

.a-project-detail-info span {
  margin-left: 10px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: bold !important;
  font-family: Montserrat;
}

#a-graphics-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#a-line-over-img {
  height: 5px;
  border-radius: 4px;
  width: 40%;
  z-index: 2;
  margin: -6px 0 10px 10px;
  position: relative;
}

.a-card-img {
  object-fit: cover;
}

.a-card-img,
.a-default-card-img {
  height: 200px;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 0;
  z-index: 1;
}

#a-general-img-card {
  width: 100%;
  margin-right: 20px;
}

.a-default-card-img {
  box-shadow: 0 0 10px 0 rgba(110, 87, 87, 0.04);
  object-fit: contain;
  text-align: center;
  padding: 40px;
}

#a-desktop-project-type-card {
  position: absolute;
  right: 0;
  width: 200px;
  height: 106%;
  border-radius: 10px;
  margin: 20px;
  padding: 35px 17px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
}

#a-desktop-project-type-card strong::first-letter,
#a-mobile-project-type-card strong::first-letter {
  text-transform: uppercase;
}

#a-mobile-project-type-card {
  display: none;
  width: 100%;
  margin: 12px 0;
  padding: 12px 20px;
  border-radius: 12px;
  color: white;
}
#a-mobile-project-type-card div {
  display: flex;
  flex-flow: column nowrap;
}

#a-type-text {
  text-transform: uppercase;

  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.22px;
}
</style>
