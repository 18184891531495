import Axios from "axios";

let http = "https://fisico.gobiernocontigo.com/api/v1/";

export default {
  index() {
    return Axios.get(`${http}projectv2/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
  publicIndex() {
    return Axios.get(`${http}projectv2/?active=true`);
  },

  // show(id) {},

  update(id, data) {
    return Axios.put(`${http}project/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  create(data) {
    return Axios.post(`${http}project/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
  createBuilder(data) {
    return Axios.post(`${http}builderproject/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
  deleteBuilder(data) {
    return Axios.delete(`${http}builderproject/${data}/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  updateState(data) {
    delete data.ruta_imagen;
    delete data.projectType;

    return Axios.put(`${http}project/${data.id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
};
