import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/iniciar-sesion",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      title: "Iniciar sesión",
    },
  },
  {
    path: "/administrador/proyectos",
    name: "Projects",
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/Secure/Projects.vue"),
    meta: {
      display: "Inicio / Proyectos",
      auth: true,
      title: "Proyectos",
    },
  },
  {
    path: "/administrador/constructores",
    name: "Builders",
    component: () =>
      import(/* webpackChunkName: "builders" */ "../views/Secure/Builders.vue"),
    meta: {
      display: "Inicio / Constructores",
      auth: true,
      title: "Constructoras",
    },
  },
  {
    path: "/administrador/estados",
    name: "ProjectTypes",
    component: () =>
      import(
        /* webpackChunkName: "builders" */ "../views/Secure/ProjectTypes.vue"
      ),
    meta: {
      display: "Inicio / Estados",
      auth: true,
      title: "Estados",
    },
  },
  {
    path: "/administrador/banners",
    name: "Banner",
    component: () =>
      import(/* webpackChunkName: "banners" */ "../views/Secure/Banners.vue"),
    meta: {
      display: "Inicio / Banners",
      auth: true,
      title: "Banners",
    },
  },
  {
    path: "/administrador/usuarios",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Secure/Users.vue"),
    meta: {
      display: "Inicio / Usuarios",
      auth: true,
      title: "Usuarios",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);
  const isAuthenticated = sessionStorage.getItem("cfta");

  if (requiresAuth && isAuthenticated == null) {
    next("/iniciar-sesion");
  } else if (isAuthenticated != null && to.name == "Login") {
    next("/administrador/proyectos");
  } else {
    next();
  }
});

export default router;
