<template>
  <Menu @openMenu="settings.showMobileMenu = true" />
  <div id="a-banner-container">
    <Banner />
  </div>
  <div id="a-home-container">
    <div v-if="isLoading">
      <Skeleton width="100%" height="400px"></Skeleton>
      <div id="a-cards-skeleton">
        <Skeleton height="200px" v-for="index in 3" :key="index" />
      </div>
    </div>

    <div v-if="!isLoading">
      <div class="a-decorative-container">
        <div id="a-main-search-container">
          <span id="a-title">Consulta las construcciones registradas</span>

          <div id="a-form-search-container">
            <InputText
              placeholder="Buscar proyecto"
              v-model="settings.search.text"
            />
            <!-- <div id="a-states-main-container">
              <span>Estados</span>
              <div id="a-states-container">
                <div
                  class="a-states"
                  v-for="index in 3"
                  :key="index"
                  v-tooltip.bottom.focus="'Click to proceed'"
                ></div>
              </div>
            </div> -->

            <button
              :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
              @click="searchProject"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
      <div id="a-home">
        <Map
          :markers="mapConfig.markers"
          v-if="!isLoading"
          :key="mapConfig.key"
        />
      </div>
      <div id="a-projects-container">
        <ProjectCard
          v-for="(project, index) in projects.slice(
            pagination.currentPage * pagination.perPage,
            pagination.currentPage * pagination.perPage + pagination.perPage
          )"
          :key="index"
          :project="project"
          @click="openModal(project)"
        />
      </div>
      <Paginator
        :rows="6"
        :first="pagination.first"
        :page="pagination.currentPage"
        :totalRecords="pagination.total"
        template=" PageLinks PrevPageLink NextPageLink "
        @page="onPage($event)"
      >
      </Paginator>
    </div>
  </div>
  <Footer />

  <Dialog
    :header="settings.project.name"
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :first="0"
    :modal="true"
    :breakpoints="{ '960px': '90vw' }"
    :style="{ width: '75vw' }"
  >
    <DetailProject :item="settings.project">
      <Map
        :isMarkerClickable="false"
        :markers="[
          {
            position: {
              lat: parseFloat(settings.project.latitude.replace(',', '.')),
              lng: parseFloat(settings.project.longitude.replace(',', '.')),
            },
            icon: 'undefined',
            project: settings.project,
          },
        ]"
      />
    </DetailProject>
  </Dialog>

  <transition name="bounce">
    <div
      id="a-overlay-mobile-menu"
      v-show="settings.showMobileMenu"
      @click="settings.showMobileMenu = false"
    >
      <MobileMenu />
    </div>
  </transition>

  <Toast />
</template>

<script>
import Menu from "@/components/navigation/Public.vue";
import MobileMenu from "@/components/navigation/PublicMobile.vue";
import Footer from "@/components/public/Footer.vue";
import Banner from "@/components/public/Banner.vue";
import ProjectCard from "@/components/public/ProjectCard.vue";
import ProjectAPI from "@/services/projects.js";
import Map from "@/components/Maps/Map.vue";
import DetailProject from "@/components/projects/PublicDetailProject.vue";

export default {
  components: {
    Menu,
    Footer,
    ProjectCard,
    Map,
    DetailProject,
    MobileMenu,
    Banner,
  },
  data() {
    return {
      projects: [],
      originals: [],
      isLoading: true,
      pagination: {
        perPage: 6,
        first: 0,
        currentPage: 0,
        total: 0,
      },
      mapConfig: {
        markers: [],
        key: 0,
      },
      settings: {
        detailModal: false,
        project: null,
        search: {
          text: "",
          type: null,
        },
        showMobileMenu: false,
      },
    };
  },
  methods: {
    getProjects() {
      ProjectAPI.publicIndex()
        .then((response) => {
          this.projects = response.data;
          this.pagination.total = this.projects.length;
          this.setMap();
        })
        .catch((error) => {
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            errors.forEach((err) => {
              message += response[err];
            });
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: message,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    setMap() {
      this.mapConfig.markers = [];
      this.projects.forEach((element) => {
        let icon = "undefined";
        if (element.projectType != null) {
          if (element.projectType.color == "00d986") {
            icon = "green";
          }
          if (element.projectType.color == "d90024") {
            icon = "red";
          }
          if (element.projectType.color == "fdc30f") {
            icon = "yellow";
          }
          if (element.projectType.color == "2b5ac4") {
            icon = "blue";
          }
          if (element.projectType.color == "ca00d9") {
            icon = "pink";
          }
        }
        this.mapConfig.markers.push({
          position: {
            lat: parseFloat(element.latitude.replace(",", ".")),
            lng: parseFloat(element.longitude.replace(",", ".")),
          },
          icon: icon,
          project: element,
        });
        this.key++;
        this.isLoading = false;
      });
    },
    onPage(event) {
      this.pagination.currentPage = event.page;
    },
    openModal(item) {
      this.settings.detailModal = true;
      this.settings.project = item;
    },
    searchProject() {
      let search = this.settings.search.text;
      if (this.originals.length == 0) {
        this.originals = this.projects;
        this.projects = this.projects.filter(function (el) {
          return el.name.toLowerCase().includes(search.toLowerCase());
        });
        this.setMap();
      } else {
        this.projects = this.originals.filter(function (el) {
          return el.name.toLowerCase().includes(search.toLowerCase());
        });

        this.setMap();
      }
      this.pagination.total = this.projects.length;
      this.pagination.first = 0;
    },
  },
  mounted() {
    this.getProjects();
    this.pagination.first = 0;
  },
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.bounce-leave-active {
  animation: bounce-in 0.7s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

#a-home-container {
  margin: 70px;
  margin-top: 20px;
}
#a-cards-skeleton {
  margin: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
#a-projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px -0.5rem;
}

#a-title {
  color: var(--color-2);
  font-family: "Quicksand", sans-serif !important;
  font-size: 18px;
  font-weight: 900;
}
#a-states-main-container span {
  margin: 0 10px;
  font-size: 14px;
}
#a-states-main-container {
  display: flex;
  align-items: center;
  background: var(--color-4);
  width: fit-content;
  padding: 0 20px;
  border-radius: 10px;
  margin: 0 20px;
  max-height: 48px;
}
#a-states-container {
  display: flex;
}
.a-states {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: red;
  margin: 0 5px;
  transition: 0.5s;
}

.a-states:hover {
  width: 30px;
  height: 30px;
  border: 2px solid white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.a-main-btn {
  width: 100px !important;
  min-width: 100px !important;
  margin: 0;
  padding: 0;
}

#a-overlay-mobile-menu {
  height: 100%;
  width: 100%;
  background: #333333ee;
  z-index: 99999999;
  position: fixed;
  top: 0;
  padding: 40px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 480px) {
  #a-projects-container {
    widows: 100%;
    min-width: 100%;
    margin: 0;
  }
  #a-home-container {
    margin: 30px;
  }
  .p-card {
    min-width: 100%;
    width: 100%;
    margin: 20px 0;
  }
}

@media (max-width: 954px) {
  #a-form-search-container {
    flex-flow: column nowrap !important;
    margin: 5px !important;
    align-items: center;
  }
  #a-title {
    font-size: 18px;
  }
  .a-main-btn {
    margin: 10px 0;
    font-size: 14px;
  }
}
</style>

<style>
.p-card:hover {
  cursor: pointer;
}
</style>
