<template>
  <Card :style="`background: #${backgroundColor}`" :key="project.id">
    <template #header>
      <img
        :class="project.ruta_imagen != null ? 'a-img-project' : 'a-default-img'"
        :src="
          project.ruta_imagen != null
            ? project.ruta_imagen
            : require('@/assets/images/public/image.svg')
        "
      />
    </template>
    <template #title>
      <h3>{{ project.name }}</h3>
    </template>
    <template #content>
      <span v-if="project.type == 'Formal'"
        ><strong>Urbanizador: </strong> {{ project.builder.name }}</span
      >
      <br />
      <span
        ><strong>Unidades de vivienda: </strong> <br />
        {{ project.housingUnits }}
      </span>
      <br /><br />
      <span>{{ project.address }}</span>
    </template>
    <template #interactions>
      <span v-if="project.type == 'Formal'">
        <img src="@/assets/icons/builder.svg"
      /></span>
      <span v-if="project.type == 'Informal'">
        <img src="@/assets/icons/builder.svg"
      /></span>
    </template>
  </Card>
</template>

<script>
export default {
  props: {
    project: Object,
  },
  computed: {
    backgroundColor() {
      return this.project.projectType
        ? `${this.project.projectType.color}30`
        : "fff";
    },
  },
};
</script>

<style>
.p-card-header {
  background: var(--color-4);
  height: 194px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px !important;
}

.p-card {
  min-width: 400px;
  min-height: 300px;
  flex: 1 1 0;
  margin: 0.5rem;
  color: var(--text-color);
  box-shadow: none !important;
  border-radius: 15px !important;
}
.p-card-title {
  color: var(--color-1);
  font-size: 20px !important;
  font-family: "Montserrat", sans-serif !important;
}
.p-card-content {
  font-family: "Montserrat", sans-serif !important;
}

@media (max-width: 480px) {
  .p-card-title {
    font-size: 15px !important;
  }
  .p-card-content {
    font-size: 13px;
  }

  .p-card-header,
  .a-img-project {
    height: 120px !important;
  }
}
</style>

<style scoped>
.a-default-img {
  height: 60px;
}

.a-img-project {
  object-fit: cover;
  height: 194px;
  border-radius: 15px !important;
}
</style>
