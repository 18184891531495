<template>
  <Menubar>
    <template #start>
      <div>
        <img
          :src="require('@/assets/images/logo/villavo-white.svg')"
          class="a-logo"
        />
      </div>
      <hr
        style="width: 1px; display: inline-block; margin: 10px"
        class="a-logo"
      />
      <img
        :src="require('@/assets/images/logo/habitat-contigo-letters.svg')"
        class="a-logo"
      />
    </template>
    <template #end>
      <div class="a-desktop-options">
        <button class="a-menubar-btn">Inicio</button>
        <a
          class="a-menubar-btn"
          href="https://play.google.com/store/apps/details?id=rae.tics"
          target="__blank__"
          style="text-decoration: none"
          >Reportes
        </a>
        <button class="a-menubar-btn" @click="$router.push('/iniciar-sesion')">
          Administrador
        </button>
      </div>
      <div class="a-mobile-options">
        <button class="a-menubar-btn">
          <img
            :src="require('@/assets/icons/menu-green.svg')"
            height="24"
            @click="showMobileMenu()"
          />
        </button>
      </div>
    </template>
  </Menubar>
</template>

<script>
export default {
  emits: ["openMenu"],
  methods: {
    showMobileMenu() {
      this.$emit("openMenu");
    },
  },
};
</script>

<style>
.p-menubar {
  background: var(--color-1) !important;
  border-radius: 0 !important;
  color: white;
  min-height: 70px;
}

.p-menubar #a-menu-btn {
  min-height: 86px;
  min-width: 86px;
  padding: 1rem;
  background: var(--text-color);
  margin-right: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-menubar-start {
  display: flex;
  align-items: center;
}

.p-menubar-end {
  margin-right: 40px;
}

.a-menubar-btn {
  color: white;
  border: none;
  background: transparent;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600;
  transition: 0.5s;
  margin: 0 10px;
}
.a-menubar-btn:hover {
  cursor: pointer;
  font-weight: 800;
}

.p-menubar .p-menubar-button {
  display: none !important;
}

@media (max-width: 480px) {
  .p-menubar-end {
    margin-right: 0 !important;
  }
}
</style>

<style scoped>
#a-title {
  color: var(--color-3);
  font-family: Quicksand;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: -0.46px;
  text-align: left;
}
.a-logo {
  height: 30px;
}
.a-mobile-options {
  display: none;
}

@media (max-width: 480px) {
  .a-logo {
    height: 20px;
  }
  #a-title {
    font-size: 13px;
  }
  .a-desktop-options {
    display: none;
  }
  .a-mobile-options {
    display: block;
  }
}
</style>
