<template>
  <div id="a-logo-container">
    <img
      :src="require('@/assets/images/logo/villavo-white.svg')"
      class="a-logo"
    />
    <hr
      style="width: 0.5px; display: inline-block; margin: 10px"
      class="a-logo"
    />
    <img
      :src="require('@/assets/images/logo/habitat-contigo.svg')"
      class="a-logo"
    />
  </div>
  <div id="a-mobile-menu-content">
    <button class="a-menubar-btn a-active">Inicio</button>
    <a
      class="a-menubar-btn"
      href="https://play.google.com/store/apps/details?id=rae.tics"
      target="__blank__"
      style="text-decoration: none"
      >Reportes
    </a>
    <button class="a-menubar-btn" @click="$router.push('/iniciar-sesion')">
      Administrador
    </button>
  </div>
  <div id="a-mobile-footer">
    <div id="a-social-btn">
      <div id="a-btns">
        <a href="https://www.facebook.com/harmanfelipe/" target="_blank">
          <div id="a-rounded-social-btn">
            <img :src="require('@/assets/icons/facebook.svg')" />
          </div>
        </a>
        <a href="https://twitter.com/harmanfelipe" target="_blank">
          <div id="a-rounded-social-btn">
            <img :src="require('@/assets/icons/instagram.svg')" />
          </div>
        </a>
        <a href="https://www.instagram.com/harmanfelipe/" target="_blank">
          <div id="a-rounded-social-btn">
            <img :src="require('@/assets/icons/twitter.svg')" />
          </div>
        </a>
      </div>

      <img
        :src="require('@/assets/images/public/social-message.svg')"
        height="20"
      />
    </div>

    <span>
      <strong>
        Desarrollado por la Dirección de Sistemas Tecnológicos e Informáticos -
        Secretaría TIC
      </strong>
    </span>
  </div>
</template>

<style scoped>
#a-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.a-logo {
  height: 30px;
  object-fit: contain;
}
.a-active {
  color: var(--color-3);
  text-decoration: underline;
  text-underline-offset: 10px;
}
#a-mobile-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 150px;
}

#a-mobile-footer {
  color: white;
}

#a-social-btn {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0;
}
#a-social-btn #a-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
}

#a-rounded-social-btn img {
  height: 12px !important;
  object-fit: contain;
}
</style>
