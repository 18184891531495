<template>
  <div class="a-carousel-arrows-left">
    <a @click="onPrev">
      <div id="a-rounded-icon-btn">
        <img
          :src="require('@/assets/icons/arrow-left-white.svg')"
          style="height: 30px"
        />
      </div>
    </a>
  </div>

  <!-- <transition-group name="banner"> -->
  <div v-for="i in [currentIndex]" :key="i" class="imgs-slide">
    <img :src="banners[currentIndex].attached" />
  </div>
  <!-- </transition-group> -->

  <div class="a-carousel-arrows-right">
    <a @click="onNext">
      <div id="a-rounded-icon-btn">
        <img :src="require('@/assets/icons/arrow-right-white.svg')" />
      </div>
    </a>
  </div>
</template>

<script>
import BannerAPI from "@/services/banner.js";
export default {
  data() {
    return {
      currentIndex: 0,
      banners: [{ attached: require("@/assets/images/public/banner.svg") }],
      items: [
        {
          id: 1,
        },
        {
          id: 2,
        },
      ],
    };
  },
  methods: {
    getBanners() {
      BannerAPI.publicIndex().then((response) => {
        response.data.forEach((element) => {
          this.banners.push(element);
        });
      });
    },
    onNext() {
      const first = this.banners.shift();
      this.banners = this.banners.concat(first);
    },
    onPrev() {
      const last = this.banners.pop();
      this.banners = [last].concat(this.banners);
    },
    add: function () {
      this.items.push({
        id: Math.random(),
      });
    },
  },
  mounted() {
    this.getBanners();
  },
};
</script>

<style scoped>
.a-carousel-arrows-left,
.a-carousel-arrows-right {
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-carousel-arrows-left a:hover,
.a-carousel-arrows-right a:hover {
  cursor: pointer;
}

.a-carousel-arrows-left {
  left: 0;
  margin-left: 30px;
}
.a-carousel-arrows-right {
  right: 0;
  margin-right: 30px;
}

#a-rounded-icon-btn img {
  height: 10px !important;
  object-fit: contain;
}

#a-rounded-icon-btn {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: rgba(228, 228, 228, 0.44);
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgs-slide {
  overflow: hidden;
  position: relative;
}
.imgs-slide img {
  object-fit: cover;
  width: 100%;
  height: 300px;
}
</style>
<style>
#a-banner-container {
  background: var(--color-1);
  height: 300px;
  max-height: 300px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 480px) {
  #a-banner-container,
  .imgs-slide img {
    height: 100px !important;
  }
}

</style>
