<template>
  <div id="a-footer-container">
    <div id="a-columns">
      <div class="a-footer-column">
        <img src="@/assets/images/logo/villavo-white.svg" alt="" />
        <br />
        <p>
          Este sitio cumple con el nivel de Accesibilidad Web A según los
          requisitos de conformidad de la WCAG 2.0 y NTC 5854. Para una correcta
          visualización y navegación en el sitio, se recomienda usar las últimas
          versiones de los siguientes navegadores: Internet Explorer, Mozilla
          FireFox, Google Chrome. Si su equipo no cuenta con esta versión, por
          favor realice la actualización.
        </p>
      </div>
      <div class="a-footer-column">
        <span>Acerca del sitio</span>
        <br /><br /><br />
        <a
          href="http://historico.villavicencio.gov.co/Paginas/Politicas-de-Privacidad-y-Condiciones-de-Uso.aspx"
          target="_blank"
          >Políticas de privacidad y condiciones de uso</a
        >
      </div>
      <div class="a-footer-column">
        <span>Contacto</span>
        <br /><br />
        <p>Alcaldía de Villavicencio - Meta</p>
        <p>Sede principal</p>
        <p>Dirección: Calle 40 Nº 33 - 64 Centro, Villavicencio.</p>

        <p>Código Postal: 500001</p>

        <p>
          Horario de Atención: Lunes a Viernes: 08:00 am - 11:30 am y 02:00 pm -
          05:30 pm.
        </p>

        <p>Conmuntador: (57+8) 671 58 59</p>

        <p>Correo Electrónico: alcaldia@villavicencio.gov.co</p>
      </div>
    </div>
    <hr />
    <div id="a-footer-message">
      <span>
        <strong>
          Desarrollado por la Dirección de Sistemas Tecnológicas e Informáticos
          Secretaria de las TIC
        </strong>
        - Villavicencio Cambia Contigo
      </span>

      <div id="a-social-btn">
        <img
          :src="require('@/assets/images/public/social-message.svg')"
          height="20"
        />
        <div id="a-btns">
          <a href="https://www.facebook.com/harmanfelipe/" target="_blank">
            <div id="a-rounded-social-btn">
              <img :src="require('@/assets/icons/facebook.svg')" />
            </div>
          </a>
          <a href="https://twitter.com/harmanfelipe" target="_blank">
            <div id="a-rounded-social-btn">
              <img :src="require('@/assets/icons/instagram.svg')" />
            </div>
          </a>
          <a href="https://www.instagram.com/harmanfelipe/" target="_blank">
            <div id="a-rounded-social-btn">
              <img :src="require('@/assets/icons/twitter.svg')" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#a-footer-container {
  background: var(--color-1);
  min-height: 500px;
  margin: 40px;
  border-radius: 30px;
  padding: 50px 90px;
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

#a-footer-container hr {
  width: 100%;
}

#a-footer-container #a-columns {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(7em, 1fr)); */
  grid-template-columns: 3fr 2fr 2fr;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-gap: 40px;
}

.a-footer-column {
  font-size: 14px;
  text-align: start;
}
.a-footer-column a {
  color: #ffffff;
}
.a-footer-column span {
  color: var(--color-2) !important ;
  margin-bottom: 40px;
  font-weight: bold;
}

#a-footer-message {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  font-size: 14px;
}

#a-social-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#a-social-btn #a-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#a-rounded-social-btn img {
  height: 12px !important;
  object-fit: contain;
}

@media (max-width: 700px) {
  #a-footer-container {
    padding: 50px 20px;
  }
  #a-footer-container #a-columns {
    grid-template-columns: 1fr;
  }

  #a-footer-message {
    display: flex;
    flex-flow: column-reverse nowrap;
  }

  #a-social-btn {
    flex-flow: column nowrap;
    margin: 30px 0;
  }
  #a-social-btn #a-btns {
    margin: 10px 0;
    flex-flow: row nowrap;
  }
}

@media (max-width: 480px) {
  #a-footer-container {
    margin: 30px;
  }
}
</style>
