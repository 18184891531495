<template>
  <GMapMap
    :center="mapConfig.center"
    :zoom="mapConfig.zoom"
    v-if="this.markers.length > 0"
  >
    <GMapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      @click="openModal(m.project)"
      :icon="{
        url: m.icon
          ? require(`@/assets/icons/builders/builder-${m.icon}.svg`)
          : require(`@/assets/icons/builders/builder-undefined.svg`),
        anchor: { x: 40, y: 40 },
        scaledSize: { height: 40, width: 40 },
      }"
    />
  </GMapMap>

  <Dialog
    :header="`${settings.project.name}`"
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '90vw' }"
    :style="{ width: '75vw' }"
  >
    <DetailProject :item="settings.project" />
  </Dialog>
</template>

<script>
import DetailProject from "@/components/projects/PublicDetailProject.vue";
export default {
  components: {
    DetailProject,
  },
  props: {
    markers: Array,
    isMarkerClickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      settings: {
        project: null,
        detailProject: false,
      },
      mapConfig: {
        zoom: 12,
        center: {
          lat: 4.15,
          lng: -73.633,
        },
      },
    };
  },
  methods: {
    openModal(item) {
      if (this.isMarkerClickable) {
        this.settings.detailModal = true;
        this.settings.project = item;
      }
    },
  },
};
</script>

<style>
#a-home .vue-map-container {
  width: 100%;
  height: 320px !important;
}

#a-graphics-details .vue-map-container {
  height: 200px !important;
  width: 40%;
}

.vue-map-container {
  margin: 20px 0;
}

.vue-map-container .vue-map {
  border-radius: 20px;
}
</style>
